import { Paper, Tab, Tabs } from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";
import React, { useState } from "react";
import { navigationExtensionEmotionStyle } from "../../NavigationBar/navigationExtensionAllDataStyle";
import { ContentContainer } from "../../Container/PageContentContainer";

const NavigationExtensionWireTransferPage = () => {
  const [topOffset, setTopOffset] = useState(69);

  return (
    <Paper
      square
      ref={(el) => el && setTopOffset(el.getBoundingClientRect().top)}
      sx={navigationExtensionEmotionStyle.tabs({ top: topOffset })}
    >
      <ContentContainer size={"large"}>
        <Tabs variant={"fullWidth"} scrollButtons="auto" value={false} centered>
          <Tab component={Link} to={"#wiretransferList"} label={"Überweisungen"} />
          <Tab component={Link} to={"#createNewWiretransfer"} label={"Überweisung erstellen"} />
        </Tabs>
      </ContentContainer>
    </Paper>
  );
};

export default NavigationExtensionWireTransferPage;
