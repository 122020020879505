import * as stagesCase from "../../Stages/StagesCase";
import { AbstractCase } from "../../../types/AbstractCase";
import _ from "lodash";

export const contractRequiredFields = {
  [stagesCase.STAGE_CREATED]: () => {
    return [];
  },
  [stagesCase.STAGE_DATA_COMPLETED]: () => [],
  [stagesCase.STAGE_WAITING_FOR_FEEDBACK]: (contractCase: AbstractCase) =>
    contractRequiredFields[stagesCase.STAGE_DATA_COMPLETED_LAWFIRM](contractCase),
  [stagesCase.STAGE_DATA_COMPLETED_LAWFIRM]: (contractCase: AbstractCase) => {
    let requiredFields = [];

    if (contractCase.caseCategoryAssessment === "reject") {
      requiredFields.push("assessmentRejectionText");
      return requiredFields;
    }

    if (["paymentOrder", "enforcementOrder", "lawsuit"].includes(contractCase.defenseProcessStatus)) {
      requiredFields.push("timeBarredDate");
      if (contractCase.caseCategory === "enforceClaim") {
        requiredFields.push("courtPostalCode");
      }
      if (contractCase.caseCategory === "defendClaim") {
        requiredFields.push("responsibleCourt");
      }
      if (contractCase.defenseProcessStatus === "paymentOrder") {
        requiredFields.push("paymentOrderDate");
      }
      if (contractCase.defenseProcessStatus === "enforcementOrder") {
        requiredFields.push("enforcementOrderDate");
      }
      if (contractCase.defenseProcessStatus === "lawsuit") {
        requiredFields.push("statementOfClaimFiledDate");
      }
      return requiredFields;
    }
    requiredFields.push("caseCategoryAssessment");
    requiredFields.push("timeBarredDate");
    requiredFields.push("valueInDisputePreliminary");
    if (contractCase.caseCategory === "enforceClaim") {
      requiredFields.push("objectiveLawFirm");
      requiredFields.push("extrajudicialCommunicationDeadline");
    }
    if (
      contractCase.caseCategoryAssessment !== "reject" &&
      !_.includes(["paymentOrder", "enforcementOrder", "lawsuit"], contractCase.defenseProcessStatus)
    ) {
    }

    return requiredFields;
  },
  [stagesCase.STAGE_CHECKED]: (contractCase: AbstractCase) => {
    const requiredFields = contractRequiredFields[stagesCase.STAGE_DATA_COMPLETED_LAWFIRM](contractCase);
    if (
      contractCase.caseCategoryAssessment !== "reject" &&
      !_.includes(["paymentOrder", "enforcementOrder", "lawsuit"], contractCase.defenseProcessStatus)
    ) {
      requiredFields.push("automaticallyGenerateExtrajudicialLetter");
    }
    return requiredFields;
  },
  [stagesCase.STAGE_IN_PROGRESS]: () => [],
  [stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT]: () => {
    return ["extrajudicialCommunicationSent"];
  },
  [stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED]: (contractCase: AbstractCase) => {
    let requiredFields = ["extrajudicialReaction"];
    if (contractCase.extrajudicialReaction !== "noReaction") {
      requiredFields.push("extrajudicialReactionReceived");
    }

    if (
      _.includes(["defendClaim", "enforceClaim"], contractCase.caseCategory) &&
      contractCase.extrajudicialReaction !== "fullyAgreed"
    ) {
      requiredFields.push("extrajudicialRecommendation");
    }
    return requiredFields;
  },
  [stagesCase.STAGE_ACTION_REQUIRED]: () => [],
  [stagesCase.STAGE_PREPARE_LAWSUIT]: () => {
    return [
      "insurance.extrajudicialCoverageRequestAnsweredDate",
      "insurance.extrajudicialCoverageGranted",
      "subtractExtrajudicialProcessFee",
    ];
  },
  [stagesCase.STAGE_LAWSUIT]: (contractCase: AbstractCase) => {
    let requiredFields = [
      "valueInDisputePreliminary",
      "insurance.judicialCoverageRequestAnsweredDate",
      "insurance.judicialCoverageGranted",
      "subtractExtrajudicialProcessFee",
    ];
    if (contractCase.caseCategory === "enforceClaim") {
      requiredFields.push("courtPostalCode");
      requiredFields.push("statementOfClaimSentDate");
    }
    if (contractCase.caseCategory === "defendClaim") {
      requiredFields.push("statementOfClaimFiledDate");
      requiredFields.push("responsibleCourt");
    }

    return requiredFields;
  },
  [stagesCase.STAGE_TRIAL]: () => [],
  [stagesCase.STAGE_LAWSUIT_COMPLETED]: (contractCase: AbstractCase) => {
    let requiredFields = [
      "lawsuitCompletedReason",
      "lawsuitCompletedDate",
      "valueInDispute",
      "hasStandardInvoicing",
      "subtractExtrajudicialProcessFee",
    ];
    if (!_.includes(["courtDecision", "defaultJudgement"], contractCase.lawsuitCompletedReason)) {
      requiredFields.push("customerLawsuitCompletedApprovalDate");
    }

    if (contractCase.lawsuitCompletedReason !== "lawsuitRevocation") {
      requiredFields.push("costBearingByOpponentRelative");
    }

    return requiredFields;
  },
  [stagesCase.STAGE_COMPLETED]: () => [],
};
