import React, { useState } from "react";
import {
  CreditCardOffOutlined,
  CreditCardOutlined,
  EventBusyOutlined,
  ListAltOutlined,
  MessageOutlined,
  NewReleasesOutlined,
} from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { Notification } from "../../../types/Notification";
import ApiClient from "../../../services/ApiClient";
import NavigationBarNotificationItemStyle from "./NavigationBarNotificationItemStyle";
import truncate from "truncate-html";

const getIconForType = (type: string) => {
  switch (type) {
    case "release-update":
      return <NewReleasesOutlined />;
    case "new-message":
      return <MessageOutlined />;
    case "check-transfer":
      return <CreditCardOutlined />;
    case "deleted-transfer":
      return <CreditCardOffOutlined />;
    case "paid-task-request":
      return <ListAltOutlined />;
    case "paid-task-overdue":
      return <EventBusyOutlined />;
    default:
      return <NewReleasesOutlined />;
  }
};

export default function NavigationBarNotificationItem({
  notification,
  handleClose,
  padding = 0,
}: {
  notification: Notification;
  handleClose?: () => void;
  padding?: number;
}) {
  const [isContentExpanded, setIsContentExpanded] = useState(false);

  const handleItemClick = async (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    if (target.closest("#content-box")) {
      if (notification.content !== null && notification.content.length > 125) {
        setIsContentExpanded(!isContentExpanded);
      }
    } else {
      if (notification.link) {
        window.open(notification.link, "_blank");
      }
      if (!notification.isRead) {
        await ApiClient.put(`notifications/${notification.id}`, {
          body: JSON.stringify({ isRead: true }),
        });
      }

      if (handleClose) {
        handleClose();
      }
    }
  };

  let displayedContent = "";

  if (notification.content !== null) {
    if (notification.content.length > 125 && !isContentExpanded) {
      displayedContent = truncate(notification.content, 125);
    } else {
      displayedContent = notification.content;
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        cursor: "pointer",
        p: padding,
        "&:hover": {
          backgroundColor: "#f5f5f5",
        },
      }}
      onClick={handleItemClick}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={0.5}>
          {getIconForType(notification.type)}
          <Typography variant="h6" fontWeight="bold">
            {notification.title}
          </Typography>
        </Stack>
        {!notification.isRead && <Box sx={{ color: "#008000", fontSize: "2rem", lineHeight: "1rem" }}>&#9679;</Box>}
      </Stack>

      {displayedContent !== "" && (
        <Box id="content-box" sx={NavigationBarNotificationItemStyle.contentBox}>
          <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: displayedContent }} />
        </Box>
      )}
    </Box>
  );
}
