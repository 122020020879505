import React, { PropsWithChildren } from "react";
import { submitButtonStyles } from "./submitButtonStyles";
import ButtonLoading, { ButtonLoadingProps } from "./ButtonLoading";
import { Box, SxProps } from "@mui/material";

type SubmitButtonProps = {
  error?: boolean;
  disabled?: boolean;
  variant?: string;
  sx?: SxProps;
} & ButtonLoadingProps;

const SubmitButton = ({
  error = false,
  disabled = false,
  children,
  sx = {},
  ...rest
}: PropsWithChildren<SubmitButtonProps>) => {
  return (
    <Box sx={sx}>
      {error && (
        <Box sx={submitButtonStyles.error}>
          Leider sind Ihre Angaben noch nicht richtig oder vollständig. Bitte prüfen Sie Ihre Angaben oben.
        </Box>
      )}
      <ButtonLoading sx={submitButtonStyles.button} disabled={disabled || error} {...rest}>
        {children}
      </ButtonLoading>
    </Box>
  );
};

export default SubmitButton;
