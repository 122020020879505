import _ from "lodash";
import React, { useRef } from "react";
import LegalbirdIoConfirm from "../../Modal/LegalbirdIoConfirm";
import makeStyles from "@mui/styles/makeStyles";
import formElementAlertStyle from "./formElementAlertStyle";
import { translate } from "../../../services/Translations/translatorService";

// @ts-ignore
const useStyles = makeStyles(formElementAlertStyle);

type FormElementAlertProps = {
  isShowAlert: boolean;
  setIsShowAlert: Function;
  alert: Record<string, any>;
  initialValue: string | number;
  currentValue: string | number;
  handleChange: Function;
  label: string;
  path: string;
};

export default function FormElementAlert({
  isShowAlert,
  setIsShowAlert,
  alert,
  initialValue,
  currentValue,
  handleChange,
  label,
  path,
}: FormElementAlertProps) {
  const classes = useStyles();
  const isAlertConfirmed = useRef(false);

  const getAlertContent = () => {
    if (currentValue === initialValue) {
      return null;
    }
    const action = _.isEmpty(initialValue) ? "onInitial" : "onChange";
    if (!alert[action]) {
      return null;
    }
    return (
      <p className={classes.content}>
        Sie möchten den Wert im Feld "{label}" {action === "onInitial" ? "erstmalig eintragen" : "ändern"}
        {_.isString(currentValue) && (
          <>
            <br />"{translate(`${path}.values.${initialValue}`, initialValue || "Leer")}"
            {' => "' + translate(`${path}.values.${currentValue}`, currentValue)}"
          </>
        )}
        <br />
        <br />
        {alert[action]}
      </p>
    );
  };

  if (!isShowAlert || !getAlertContent()) {
    return null;
  }

  const onCloseAlert = () => {
    if (isAlertConfirmed.current !== true) {
      handleChange({
        target: {
          name: path,
          value: initialValue,
        },
      });
    }
    setIsShowAlert(false);
  };

  const onConfirmAlert = () => {
    isAlertConfirmed.current = true;
  };

  return (
    <LegalbirdIoConfirm
      headline="Achtung"
      confirmText="Übernehmen"
      content={getAlertContent()}
      handleConfirm={onConfirmAlert}
      handleClose={onCloseAlert}
      open={true}
    />
  );
}
