import React, { useEffect, useState } from "react";
import { apiGet } from "../../../services/Api/apiCall";
import { WireTransfer } from "../../../types/WireTransfer";
import PageContentContainer from "../../Container/PageContentContainer";
import PageHeadline from "../../PageHeadline/PageHeadline";
import AddWiretransfer from "../../Wiretransfer/AddWiretransfer";
import WiretransferList from "../../Wiretransfer/WiretransferList";
import NavigationExtensionWireTransferPage from "./NavigationExtensionWireTransferPage";

export default function WireTransferPage() {
  const [wireTransferCollection, setWireTransferCollection] = useState<WireTransfer[] | null>(null);

  const fetchWireTransfers = () => {
    apiGet("wiretransfers?exists[paymentRun]=false&exists[deletedDate]=false").then((result) =>
      setWireTransferCollection(result["hydra:member"])
    );
  };

  useEffect(() => {
    fetchWireTransfers();
  }, []);

  return (
    <>
      <NavigationExtensionWireTransferPage />
      <PageContentContainer>
        <PageHeadline main={"Überweisungen"} />
        <WiretransferList wireTransferCollection={wireTransferCollection} refreshCollection={fetchWireTransfers} />
        <AddWiretransfer postSubmitCallback={fetchWireTransfers} />
      </PageContentContainer>
    </>
  );
}
