/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React, { useMemo } from "react";
import ContentBox from "../ContentBox/ContentBox";
import LegalbirdLoader from "../ContentLoader/LegalbirdLoader";
import { Grid, IconButton, List, ListItem, Typography } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import NavigationBarNotificationItem from "../NavigationBar/NavigationBarNotificationItem/NavigationBarNotificationItem";
import { CheckCircle } from "@mui/icons-material";
import { apiGet } from "../../services/Api/apiCall";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/ReactQuery/reactQueryService";

export default function Notifications() {
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();

  let notificationsFilter = {
    "created[after]": moment().subtract(2, "months").format("YYYY-MM-DD"),
    backofficeUser: currentUser["@id"],
  };

  const notificationsKey = queryKeys.collection("notifications", notificationsFilter);

  const { data: notifications } = useQuery(
    notificationsKey,
    () => fetchCollection("notifications", notificationsFilter),
    {
      select: (data) => data["hydra:member"],
    }
  );

  const newNotificationCount = useMemo(() => {
    if (!notifications) {
      return null;
    }
    return _.filter(notifications, (notification) => !notification.isRead).length;
  }, [notifications]);

  const handleAllRead = async () => {
    await apiGet("/notifications/read");
    await queryClient.invalidateQueries(notificationsKey);
  };

  const handleClose = async () => {
    await queryClient.invalidateQueries(notificationsKey);
  };

  if (!notifications) {
    return <LegalbirdLoader centered />;
  }

  return (
    <ContentBox padding={"none"}>
      {notifications.length < 1 && (
        <Typography sx={{ textAlign: "center" }}>Keine aktuellen Benachrichtigungen</Typography>
      )}

      {!!newNotificationCount && (
        <Grid container justifyContent="end" sx={{ padding: "8px" }}>
          <Grid item>
            <IconButton onClick={handleAllRead} size={"small"}>
              <CheckCircle />
            </IconButton>
          </Grid>
        </Grid>
      )}

      <List sx={{ padding: 0 }}>
        {_.map(notifications, (notification) => (
          <ListItem
            sx={{
              borderBottom: "1px solid #ddd",
              padding: 0,
              "&:last-of-type": { borderBottom: "none" },
            }}
            key={notification.id}
          >
            <NavigationBarNotificationItem notification={notification} padding={2} handleClose={handleClose} />
          </ListItem>
        ))}
      </List>
    </ContentBox>
  );
}
