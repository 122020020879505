import { AbstractCase } from "../../../types/AbstractCase";
import _ from "lodash";
import * as stagesCase from "../../Stages/StagesCase";
import { contractRequiredFields } from "../Contract/contractRequiredFields";

export const dataLeakRequiredFields = {
  [stagesCase.STAGE_CREATED]: (dataLeakCase: AbstractCase) => [],
  [stagesCase.STAGE_DATA_COMPLETED]: (dataLeakCase: AbstractCase) => [],
  [stagesCase.STAGE_WAITING_FOR_FEEDBACK]: (dataLeakCase: AbstractCase) =>
    contractRequiredFields[stagesCase.STAGE_DATA_COMPLETED_LAWFIRM](dataLeakCase),
  [stagesCase.STAGE_DATA_COMPLETED_LAWFIRM]: (dataLeakCase: AbstractCase) => {
    return contractRequiredFields[stagesCase.STAGE_DATA_COMPLETED_LAWFIRM](dataLeakCase);
  },
  [stagesCase.STAGE_CHECKED]: (dataLeakCase: AbstractCase) =>
    contractRequiredFields[stagesCase.STAGE_CHECKED](dataLeakCase),
  [stagesCase.STAGE_IN_PROGRESS]: (dataLeakCase: AbstractCase) => [],
  [stagesCase.STAGE_EXTRAJUDICIAL_COMMUNICATION_SENT]: (dataLeakCase: AbstractCase) => {
    return ["extrajudicialCommunicationSent"];
  },
  [stagesCase.STAGE_EXTRAJUDICIAL_REACTION_RECEIVED]: (dataLeakCase: AbstractCase) => {
    let requiredFields = ["extrajudicialReaction"];
    if (dataLeakCase.extrajudicialReaction !== "noReaction") {
      requiredFields.push("extrajudicialReactionReceived");
    }

    if (
      _.includes(["defendClaim", "enforceClaim"], dataLeakCase.caseCategory) &&
      dataLeakCase.extrajudicialReaction !== "fullyAgreed"
    ) {
      requiredFields.push("extrajudicialRecommendation");
    }
    return requiredFields;
  },
  [stagesCase.STAGE_ACTION_REQUIRED]: (dataLeakCase: AbstractCase) => [],
  [stagesCase.STAGE_PREPARE_LAWSUIT]: (dataLeakCase: AbstractCase) => {
    let requiredFields = [
      "insurance.extrajudicialCoverageRequestAnsweredDate",
      "insurance.extrajudicialCoverageGranted",
      "personalAffection.personalAffectionLawFirm",
      "subtractExtrajudicialProcessFee",
    ];

    if (dataLeakCase.caseCategory === "enforceClaim") {
      requiredFields.push("courtPostalCode");
    }

    return requiredFields;
  },
  [stagesCase.STAGE_LAWSUIT]: (dataLeakCase: AbstractCase) => {
    let requiredFields = [
      "valueInDisputePreliminary",
      "insurance.judicialCoverageRequestAnsweredDate",
      "insurance.judicialCoverageGranted",
      "subtractExtrajudicialProcessFee",
      "personalAffection.personalAffectionLawFirm",
    ];
    if (dataLeakCase.caseCategory === "enforceClaim") {
      requiredFields.push("courtPostalCode");
      requiredFields.push("statementOfClaimSentDate");
    }
    if (dataLeakCase.caseCategory === "defendClaim") {
      requiredFields.push("statementOfClaimFiledDate");
      requiredFields.push("responsibleCourt");
    }

    return requiredFields;
  },
  [stagesCase.STAGE_TRIAL]: (dataLeakCase: AbstractCase) => [],
  [stagesCase.STAGE_LAWSUIT_COMPLETED]: (dataLeakCase: AbstractCase) => {
    let requiredFields = [
      "lawsuitCompletedReason",
      "lawsuitCompletedDate",
      "valueInDispute",
      "hasStandardInvoicing",
      "subtractExtrajudicialProcessFee",
    ];
    if (!_.includes(["courtDecision", "defaultJudgement"], dataLeakCase.lawsuitCompletedReason)) {
      requiredFields.push("customerLawsuitCompletedApprovalDate");
    }

    if (dataLeakCase.lawsuitCompletedReason !== "lawsuitRevocation") {
      requiredFields.push("costBearingByOpponentRelative");
    }

    return requiredFields;
  },
  [stagesCase.STAGE_COMPLETED]: (dataLeakCase: AbstractCase) => [],
};
