import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ContentBox from "../ContentBox/ContentBox";
import { WireTransfer } from "../../types/WireTransfer";
import LegalbirdLoader from "../ContentLoader/LegalbirdLoader";
import { apiPost, apiPut } from "../../services/Api/apiCall";
import { Link } from "react-router-dom";
import { getCaseLinkByBackofficeCase } from "../../services/Product/ProductService";
import LegalbirdIoConfirm from "../Modal/LegalbirdIoConfirm";
import { Attachment, DeleteOutline, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import _ from "lodash";
import { userHasOneOfTheseRoles, userHasRole } from "../../services/backofficeUserService";
import { Roles } from "../../types/BackofficeUser";
import ButtonLoading from "../Button/ButtonLoading";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { TabContext, TabPanel } from "@mui/lab";
import TaggedTab from "../Tabs/TaggedTab";
import LabeledSelect from "../Select/LabeledSelect";
import DisplayMediaObject from "../MediaObjectSection/DisplayMediaObject";
import DeleteWireTransfer from "./DeleteWireTransfer";
import { translate } from "../../services/Translations/translatorService";
import { scrollToAnchor } from "../../theme/commonStyles";

export default function WiretransferList({
  wireTransferCollection,
  refreshCollection,
}: {
  wireTransferCollection: WireTransfer[] | null;
  refreshCollection: Function;
}) {
  const currentUser = useCurrentUser();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>(localStorage.getItem("wireTransferFilter") || "created");
  const [userFilter, setUserFilter] = useState<string>(localStorage.getItem("wireTransferUserFilter") || "all");

  const { lawyers, backofficeUsers } = useBackofficeUser();

  useEffect(() => {
    localStorage.setItem("wireTransferFilter", selectedTab);
    localStorage.setItem("wireTransferUserFilter", userFilter);
  }, [selectedTab, userFilter]);

  const tabs: Array<{ label: string; filterName: "created" | "factualCorrect" | "calculativeCorrect" }> = [
    {
      label: "Erstellt",
      filterName: "created",
    },
    {
      label: "Sachlich geprüft",
      filterName: "factualCorrect",
    },
    {
      label: "Rechnerisch geprüft",
      filterName: "calculativeCorrect",
    },
  ];

  const getWireTransferLists = (wireTransferCollection: Array<WireTransfer>) => {
    if (!wireTransferCollection) {
      return {
        created: [],
        factualCorrect: [],
        calculativeCorrect: [],
      };
    }

    return {
      created: wireTransferCollection.filter((wireTransfer) => {
        return !wireTransfer.factualCorrectDate && !wireTransfer.calculativeCorrectDate;
      }),
      factualCorrect: wireTransferCollection.filter((wireTransfer) => {
        return !!wireTransfer.factualCorrectDate && !wireTransfer.calculativeCorrectDate;
      }),
      calculativeCorrect: wireTransferCollection.filter((wireTransfer) => {
        return !!wireTransfer.factualCorrectDate && !!wireTransfer.calculativeCorrectDate;
      }),
    };
  };

  const createPaymentRun = async () => {
    setIsLoading(true);
    await apiPost("/lb/services/payment_run/wiretransfer", {});
    refreshCollection();
    setIsLoading(false);
  };

  if (wireTransferCollection === null) {
    return (
      <ContentBox headline={"Überweisungen"}>
        <LegalbirdLoader centered />
      </ContentBox>
    );
  }

  const filteredWireTransferCollection = wireTransferCollection.filter((wireTransfer) => {
    if (userFilter !== "all") {
      return [wireTransfer.reviewer, wireTransfer.alternativeReviewer].includes(userFilter);
    }
    return true;
  });

  const wireTransferLists = getWireTransferLists(filteredWireTransferCollection);

  const andi = backofficeUsers.find((user) => user.email === "andreas.quauke@legalbird.de");
  const melli = backofficeUsers.find((user) => user.email === "melanie.treude@legalbird.de");

  return (
    <>
      <Stack direction={"row"} justifyContent={"flex-end"} sx={{ marginBottom: "1rem" }}>
        <LabeledSelect
          value={userFilter}
          label={"Sachliche Prüfung durch"}
          onChange={(e) => setUserFilter(e.target.value)}
          fullWidth={false}
          sx={{ minWidth: 160 }}
        >
          <MenuItem value={"all"}>Alle Benutzer</MenuItem>
          {lawyers.map((lawyer) => (
            <MenuItem key={lawyer.id} value={lawyer["@id"]}>
              {lawyer.person.fullname}
            </MenuItem>
          ))}
          {andi && <MenuItem value={andi["@id"]}>{andi.person.fullname}</MenuItem>}
          {melli && <MenuItem value={melli["@id"]}>{melli.person.fullname}</MenuItem>}
        </LabeledSelect>
      </Stack>

      <Box sx={scrollToAnchor} id={"wiretransferList"} />
      <TableContainer component={Paper}>
        <TabContext value={selectedTab}>
          <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}>
            {_.map(tabs, (tab) => (
              <TaggedTab
                label={tab.label}
                entryCount={wireTransferLists[tab.filterName].length}
                value={tab.filterName}
                key={tab.filterName}
              />
            ))}
          </Tabs>
          {_.map(tabs, (tab) => (
            <TabPanel value={tab.filterName} key={tab.filterName}>
              <WireTransferTable
                wireTransferList={wireTransferLists[tab.filterName]}
                refreshCollection={refreshCollection}
              />
            </TabPanel>
          ))}
          {userHasRole(currentUser, Roles.admin) &&
            selectedTab === "calculativeCorrect" &&
            wireTransferLists.calculativeCorrect.length > 0 && (
              <Box sx={{ textAlign: "center", margin: "2rem 0" }}>
                <ButtonLoading
                  sx={{ minWidth: "230px" }}
                  variant={"contained"}
                  fullWidth={false}
                  isLoading={isLoading}
                  onClick={createPaymentRun}
                >
                  Zahllauf erstellen
                </ButtonLoading>
              </Box>
            )}
        </TabContext>
      </TableContainer>
    </>
  );
}

function WireTransferTable({
  wireTransferList,
  refreshCollection,
}: {
  wireTransferList: WireTransfer[];
  refreshCollection: Function;
}) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Eingestellt am</TableCell>
          <TableCell>Empfänger</TableCell>
          <TableCell>Betrag</TableCell>
          <TableCell>Verwendungszweck</TableCell>
          <TableCell>Fall</TableCell>
          <TableCell>Anwalt</TableCell>
          <TableCell>Sachlich geprüft</TableCell>
          <TableCell>Rechnerisch geprüft</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {wireTransferList.map((wireTransfer) => (
          <WireTransferRow key={wireTransfer.id} wireTransfer={wireTransfer} refreshCollection={refreshCollection} />
        ))}
      </TableBody>
    </Table>
  );
}

function WireTransferRow({
  wireTransfer,
  refreshCollection,
}: {
  wireTransfer: WireTransfer;
  refreshCollection: Function;
}) {
  const [factualCorrect, setFactualCorrect] = useState<boolean>(!!wireTransfer.factualCorrectDate);
  const [calculativeCorrect, setCalculativeCorrect] = useState<boolean>(!!wireTransfer.calculativeCorrectDate);
  const [modal, setModal] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [displayMediaObject, setDisplayMediaObject] = useState(false);

  const currentUser = useCurrentUser();
  const { getUserData } = useBackofficeUser();

  const toggleFactualCorrect = (value: boolean) => {
    apiPut("wiretransfers", wireTransfer.id, {
      factualCorrectDate: value ? moment() : null,
      factualCorrectBy: value ? currentUser["@id"] : null,
    }).then(() => refreshCollection());
    setFactualCorrect(value);
  };

  const toggleCalculativeCorrect = (value: boolean) => {
    apiPut("wiretransfers", wireTransfer.id, {
      calculativeCorrectDate: value ? moment() : null,
      calculativeCorrectBy: value ? currentUser["@id"] : null,
    }).then(() => refreshCollection());
    setCalculativeCorrect(value);
  };

  const isDeletable = () => {
    return userHasOneOfTheseRoles(currentUser, [Roles.accounting, Roles.admin]) || !wireTransfer.factualCorrectDate;
  };

  const createdByUser = getUserData(wireTransfer.createdBy);
  const factualCorrectByUser = getUserData(wireTransfer.factualCorrectBy);
  const calculativeCorrectByUser = getUserData(wireTransfer.calculativeCorrectBy);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset !important" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{moment(wireTransfer.created).format("DD.MM.YYYY HH:mm")} Uhr</TableCell>
        <TableCell>{wireTransfer.recipientName}</TableCell>
        <TableCell>{wireTransfer.amount} €</TableCell>
        <TableCell>{wireTransfer.subject}</TableCell>
        <TableCell>
          {!!wireTransfer.backofficeCase ? (
            <Link to={getCaseLinkByBackofficeCase(wireTransfer.backofficeCase)} target={"_blank"}>
              {wireTransfer.backofficeCase.label}
            </Link>
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell>{_.get(wireTransfer, "backofficeCase.lawyer.person.fullname", "-")}</TableCell>
        <TableCell>
          <Checkbox
            name="factualCorrect"
            checked={factualCorrect}
            onChange={() => setModal("factualCorrect")}
            value="factualCorrect"
            color="primary"
            disabled={
              !!wireTransfer.calculativeCorrectDate ||
              !userHasOneOfTheseRoles(currentUser, [Roles.lawyer, Roles.admin, Roles.accounting])
            }
          />
          <LegalbirdIoConfirm
            handleClose={() => setModal("")}
            open={modal === "factualCorrect"}
            headline={!factualCorrect ? "Sachliche Prüfung" : "Rücknahme - Sachliche Prüfung"}
            handleConfirm={() => toggleFactualCorrect(!factualCorrect)}
            confirmText={"Bestätigen"}
            content={
              !factualCorrect
                ? "Bitte bestätigen, dass der Eintrag sachlich geprüft wurde"
                : "Bitte bestätigen, dass die sachliche Prüfung zurückgenommen werden soll."
            }
          />
        </TableCell>
        <TableCell>
          <Checkbox
            name="calculativeCorrect"
            checked={calculativeCorrect}
            onChange={() => setModal("calculativeCorrect")}
            value="calculativeCorrect"
            color="primary"
            disabled={
              !wireTransfer.factualCorrectDate || !userHasOneOfTheseRoles(currentUser, [Roles.accounting, Roles.admin])
            }
          />
          <LegalbirdIoConfirm
            handleClose={() => setModal("")}
            open={modal === "calculativeCorrect"}
            headline={!calculativeCorrect ? "Rechnerische Prüfung" : "Rücknahme - Rechnerische Prüfung"}
            handleConfirm={() => toggleCalculativeCorrect(!calculativeCorrect)}
            confirmText={"Bestätigen"}
            content={
              !calculativeCorrect
                ? "Bitte bestätigen, dass der Eintrag rechnerisch geprüft wurde."
                : "Bitte bestätigen, dass die rechnerische Prüfung zurückgenommen werden soll."
            }
          />
        </TableCell>
        <TableCell>
          <Stack direction={"row"}>
            {wireTransfer.receipt && (
              <>
                <Tooltip title={"Beleg anzeigen"}>
                  <IconButton onClick={() => setDisplayMediaObject(true)} size="large">
                    <Attachment />
                  </IconButton>
                </Tooltip>
                <DisplayMediaObject
                  closeDialog={() => {
                    setDisplayMediaObject(false);
                  }}
                  mediaObject={displayMediaObject ? wireTransfer.receipt : null}
                />
              </>
            )}
            {isDeletable() && (
              <>
                <Tooltip title={"Überweisung löschen"}>
                  <IconButton onClick={() => setModal("delete")} size="large">
                    <DeleteOutline />
                  </IconButton>
                </Tooltip>
                <DeleteWireTransfer
                  open={modal === "delete"}
                  handleClose={() => setModal("")}
                  wireTransfer={wireTransfer}
                  refreshCollection={refreshCollection}
                />
              </>
            )}
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingTop: "0px" }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Typography>
                <strong>IBAN</strong>: {wireTransfer.iban}
              </Typography>
              <Typography>
                <strong>Art der Zahlung</strong>:{" "}
                {translate("wireTransfer.typeOfPayment.values." + wireTransfer.typeOfPayment)}
              </Typography>
              <Typography>
                <strong>Firmenkonto</strong>: {wireTransfer.payingOrganization?.name}
              </Typography>
              <Typography>
                <strong>Erstellt:</strong>: {moment(wireTransfer.created).format("DD.MM.YYYY HH:mm")} Uhr |{" "}
                {createdByUser?.person.fullname}
              </Typography>
              {!!wireTransfer.factualCorrectDate && (
                <Typography>
                  <strong>Sachlich geprüft:</strong>:{" "}
                  {moment(wireTransfer.factualCorrectDate).format("DD.MM.YYYY HH:mm")} Uhr |{" "}
                  {factualCorrectByUser?.person.fullname}
                </Typography>
              )}
              {!!wireTransfer.calculativeCorrectDate && (
                <Typography>
                  <strong>Rechnerisch geprüft:</strong>:{" "}
                  {moment(wireTransfer.calculativeCorrectDate).format("DD.MM.YYYY HH:mm")} Uhr |{" "}
                  {calculativeCorrectByUser?.person.fullname}
                </Typography>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
