import contractTransformations from "./contractTransformations";

const rentalContractTransformations = {
  dateFields: [
    { path: "rentalContractStartDate", type: "date" },
    { path: "rentalContractEndDate", type: "date" },
    ...contractTransformations.dateFields,
  ],
  numberFields: ["leadStatus", "applicationStatus", "responsibleCourt.advoAssistId"],
  timeFields: contractTransformations.timeFields,
  floatFields: [
    "previousBasicRent",
    "previousRentIncludingHeating",
    "futureBasicRent",
    "futureRentIncludingHeating",
    "depositAmount",
    ...contractTransformations.floatFields,
  ],
  ibanFields: contractTransformations.ibanFields,
};
export default rentalContractTransformations;
