import React, { useEffect, useMemo, useState } from "react";
import { Badge, Grid, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { CheckCircle, NotificationsOutlined, OpenInNew } from "@mui/icons-material";
import { isCookieFetched, mercureFetchCookie, mercureSubscribe } from "../../services/mercure";
import _ from "lodash";
import NavigationBarNotificationItem from "./NavigationBarNotificationItem/NavigationBarNotificationItem";
import { Notification } from "../../types/Notification";
import { apiGet } from "../../services/Api/apiCall";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import moment from "moment";

export default function NavigationBarNotifications() {
  const [notifications, setNotifications] = useState<any>(null);
  const currentUser = useCurrentUser();
  const cookieFetched = isCookieFetched();

  useEffect(() => {
    if (cookieFetched) {
      const eventSource = mercureSubscribe(currentUser["@id"] + "/notifications", setNotifications);
      return () => {
        eventSource.close();
      };
    } else {
      mercureFetchCookie();
    }
  }, [cookieFetched]);

  useEffect(() => {
    if (!notifications) {
      let options = { headers: new Headers() };
      options.headers.set("Accept", "application/json");
      apiGet(
        "notifications",
        null,
        { backofficeUser: currentUser["@id"], "created[after]": moment().subtract(30, "days").format("YYYY-MM-DD") },
        options
      ).then((notificationsResult: Notification[]) => {
        setNotifications(notificationsResult);
      });
    }
  }, [notifications]);

  return <NotificationsInner notifications={notifications} />;
}

const NotificationsInner = ({ notifications }: { notifications: Notification[] | null }) => {
  const [notificationsOpen, setNotficationsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const handleClick = ({ target }: any) => {
    setAnchorEl(target);
    setNotficationsOpen(true);
  };

  const handleAllRead = () => {
    apiGet("/notifications/read");
    setNotficationsOpen(false);
  };

  const newNotificationCount = useMemo(() => {
    if (!notifications) {
      return null;
    }
    return _.filter(notifications, (notification) => !notification.isRead).length;
  }, [notifications]);

  const handleOpenInNew = () => {
    const url = `${window.location.origin}/services/notifications`;

    window.open(url, "_blank");
  };

  return (
    <>
      <IconButton onClick={handleClick} size={"large"}>
        <Badge badgeContent={newNotificationCount} color={"primary"}>
          <NotificationsOutlined sx={{ height: "30px", width: "auto" }} />
        </Badge>
      </IconButton>
      {!!notifications && notifications.length > 0 ? (
        <Menu
          open={notificationsOpen}
          onClose={() => setNotficationsOpen(false)}
          anchorEl={anchorEl}
          disableScrollLock={true}
          slotProps={{
            paper: {
              sx: { width: "520px" },
            },
          }}
          MenuListProps={{
            sx: { padding: 0 },
          }}
        >
          <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ padding: "8px 16px" }}>
            <Grid item>
              <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                Benachrichtigungen
              </Typography>
            </Grid>
            <Grid item>
              {!!newNotificationCount && (
                <IconButton onClick={handleAllRead} size={"small"}>
                  <CheckCircle />
                </IconButton>
              )}
              <IconButton onClick={handleOpenInNew} size={"small"}>
                <OpenInNew />
              </IconButton>
            </Grid>
          </Grid>
          {_.map(notifications, (notification) => (
            <MenuItem
              sx={{
                borderBottom: "1px solid #ddd",
                "&:last-of-type": { borderBottom: "none" },
              }}
              key={notification.id}
            >
              <NavigationBarNotificationItem
                notification={notification}
                handleClose={() => setNotficationsOpen(false)}
              />
            </MenuItem>
          ))}
        </Menu>
      ) : (
        <Menu
          open={notificationsOpen}
          onClose={() => setNotficationsOpen(false)}
          anchorEl={anchorEl}
          disableScrollLock={true}
        >
          <MenuItem onClick={() => setNotficationsOpen(false)} sx={{ textAlign: "center" }}>
            Keine aktuellen Benachrichtigungen
          </MenuItem>
        </Menu>
      )}
    </>
  );
};
