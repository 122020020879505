/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

export const navigationBarNotificationItemStyle = {
  contentBox: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    padding: "0.5rem",
    border: "1px solid #E9E5E5",
    borderRadius: "4px",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    "& p": { margin: 0 },
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
};

export default navigationBarNotificationItemStyle;
