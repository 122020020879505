/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import PageContentContainer from "../../Container/PageContentContainer";
import PageHeadline from "../../PageHeadline/PageHeadline";
import Notifications from "../../Notifications/Notifications";
import { Box } from "@mui/material";

const NotificationsPage = () => {
  return (
    <PageContentContainer>
      <PageHeadline main={"Benachrichtigungen"} />
      <Box sx={{ width: "800px", margin: "auto" }}>
        <Notifications />
      </Box>
    </PageContentContainer>
  );
};
export default NotificationsPage;
