const courtAppointmentTranslations = {
  courtDate: {
    appointmentDate: {
      label: "Gerichtstermin Datum",
    },
    appointmentTime: {
      label: "Gerichtstermin Uhrzeit",
    },
  },
  courtDateType: {
    label: "Art des Termins",
    values: {
      hearing: "Mündliche Verhandlung",
      hearingOfEvidence: "Mündliche Verhandlung mit Beweisaufnahme",
      settlement: "Gütetermin",
      trialEmploymentLaw: "Kammertermin",
      trialTrafficLaw: "Gerichtstermin",
      divorce: "Scheidungstermin",
    },
  },
  attendeeType: {
    label: "Art des Terminwahrnehmers",
    values: {
      advoAssist: "AdvoAssist",
      individual: "Individueller Anwalt",
    },
  },
  advoAssistIndependentLawyer: {
    gender: {
      values: {
        male: "Herr",
        female: "Frau",
      },
      label: "Anrede Anwalt Gerichtstermin",
    },
    givenName: {
      label: "Vorname Anwalt Gerichtstermin",
    },
    familyName: {
      label: "Nachname Anwalt Gerichtstermin",
    },
    email: {
      label: "E-Mail Anwalt Gerichtstermin",
    },
  },
  customNotes: {
    label: "Sonstige Informationen",
  },
  personalAppearanceClient: {
    label: "Persönliches Erscheinen Mandantschaft",
    values: {
      ordered: "Angeordnet",
      yes: "Ja",
      no: "Nein",
    },
  },
};

export default courtAppointmentTranslations;
